<template>
  <div>
    <!--begin::customer-->
    <!--    <div class="card card-custom">-->
    <div>
      <!--      <div class="card-header flex-wrap border-0 pt-6 pb-0">-->
      <!--        <div class="card-title m-0">-->
      <h3 class="card-label">
        {{ $t('suppliers.suppliers_management') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="card-body">-->

      <!--        <div class="card-body p-0">-->
      <b-tabs content-class="mt-3" class="nav-custom-link">
        <b-tab :title="$t('suppliers.basic_information')" @click="toggleTab('basic_information')" active>
          <div class="row">
            <div class="col-md-4">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('suppliers.business_name') }}<span class="text-danger">*</span></label>
                    <input type="text" v-model="data.business_name" class="form-control" :class="validation && validation.business_name ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.business_name" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.business_name[0] }}
                                        </span>
                  </div>
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('suppliers.first_name') }}<span class="text-danger">*</span></label>
                    <input type="text" v-model="data.first_name" class="form-control" :class="validation && validation.first_name ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.first_name" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.first_name[0] }}
                                        </span>
                  </div>
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('suppliers.last_name') }}</label>
                    <input type="text" v-model="data.last_name" class="form-control" :class="validation && validation.last_name ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.last_name" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.last_name[0] }}
                                        </span>
                  </div>
                  <div class="col-lg-12  mb-5">
                    <label>{{ $t('suppliers.supplier_code') }}</label>
                    <input type="text" v-model="data.supplier_code" class="form-control" :class="validation && validation.supplier_code ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.supplier_code" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.supplier_code[0] }}
                                        </span>
                  </div>
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('suppliers.buisnessno') }}</label>
                    <input type="text" v-model="data.buisnessno" class="form-control" :class="validation && validation.buisnessno ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.buisnessno" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.buisnessno[0] }}
                                        </span>
                  </div>
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('suppliers.taxno') }}</label>
                    <input type="text" v-model="data.taxno" class="form-control" :class="validation && validation.taxno ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.taxno" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.taxno[0] }}
                                        </span>
                  </div>
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('suppliers.default_currency') }}</label>
                    <div class="input-group mb-3">
                      <select name="" v-model="data.default_currency" id="" class="custom-select">
                        <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                      </select>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span v-if="validation && validation.default_currency" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.default_currency[0] }}
                                        </span>
                  </div>
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('branch') }}</label>
                    <div class="input-group">

                      <multiselect v-model="branch"
                                   :placeholder="$t('branch')"
                                   label="name"
                                   track-by="id"
                                   :options="branches"
                                   :multiple="false"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false">
                      </multiselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/settings/branches" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span v-if="validation && validation.branch_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.branch_id[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-12 mb-5">
                    <label>&ensp;</label>
                    <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{ $t('status') }}</b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-8">
              <div class="card card-custom">
                <div class="card-header pl-6 pr-6">
                  <div class="card-title">
                    <h4 class="m-0">{{ $t('suppliers.contact_information') }}</h4>
                  </div>
                </div>
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('suppliers.telephone') }}</label>
                    <!-- <input type="text" v-model="data.phone" class="form-control" :class="validation && validation.telephone ? 'is-invalid' : ''"/> -->
                    <vue-tel-input v-model="data.phone" :class="validation && validation.phone ? 'is-invalid' : ''"></vue-tel-input>

                    <span v-if="validation && validation.telephone" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.telephone[0] }}
                                        </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('suppliers.mobile') }}<span class="text-danger">*</span></label>
                    <!-- <input type="text" v-model="data.mobile" class="form-control" :class="validation && validation.mobile ? 'is-invalid' : ''"/> -->
                    <vue-tel-input v-model="data.mobile" :class="validation && validation.mobile ? 'is-invalid' : ''"></vue-tel-input>
                    <span v-if="validation && validation.mobile" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.mobile[0] }}
                                        </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('suppliers.email') }}</label>
                    <input type="text" v-model="data.email" class="form-control" :class="validation && validation.email ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.email" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.email[0] }}
                                        </span>
                  </div>
                </div>
              </div>
              <div class="card card-custom mt-5">
                <div class="card-header pl-6 pr-6">
                  <div class="card-title">
                    <h4 class="m-0">{{ $t('suppliers.address_information') }}</h4>
                  </div>
                </div>
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('suppliers.country') }}</label>
                    <!--                                      <div class="input-group mb-3">-->
                    <multiselect v-model="country"
                                 :placeholder="$t('suppliers.country')"
                                 label="name" track-by="code2"
                                 :options="countries"
                                 :multiple="false"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false"
                                 @search-change="getCountries($event)"
                                 :internal-search="false">
                    </multiselect>
                    <!--                                      <div class="input-group-prepend">-->
                    <!--                                        <a class="btn btn-primary" href="/settings/countries" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>-->
                    <!--                                      </div>-->
                    <!--                                    </div>-->
                    <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('suppliers.country') }}.</span>
                    <span v-if="validation && validation.country_code" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.country_code[0] }}
                                        </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('suppliers.city') }}</label>
                    <div class="input-group mb-3">
                      <multiselect v-model="city"
                                   :placeholder="$t('suppliers.city')"
                                   label="name" track-by="id"
                                   :options="cities"
                                   :multiple="false"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false">
                      </multiselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" :href="data.country_code?'/settings/cities/'+data.country_code:'#'" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span v-if="validation && validation.city_id" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.city_id[0] }}
                                        </span>
                  </div>
                  <div class="col-lg-6 mb-5" v-if="country.id != 192">
                    <label>{{ $t('suppliers.postal_code') }}</label>
                    <input type="text" class="form-control" :class="validation && validation.postal_code? 'is-invalid': ''" v-model="data.postal_code"/>
                    <span v-if="validation && validation.postal_code" class="fv-plugins-message-container invalid-feedback">
                                              {{ validation.postal_code[0] }}
                                              </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{country.id != 192 ? $t('suppliers.address_1') : $t('national_address') }}</label>
                    <input type="text" class="form-control" :class="  validation && validation.address_1 ? 'is-invalid' : ''" v-model="data.address_1"/>
                    <span v-if="validation && validation.address_1" class="fv-plugins-message-container invalid-feedback">
                                              {{ validation.address_1[0] }}
                                              </span>
                  </div>
                  <div class="col-lg-6 mb-5" v-if="country.id != 192">
                    <label>{{ $t('suppliers.address_2') }}</label>
                    <input type="text" class="form-control" :class=" validation && validation.address_2 ? 'is-invalid' : ''" v-model="data.address_2"/>
                    <span v-if="validation && validation.address_2" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.address_2[0] }}
                                          </span>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="row mt-5">

            <div class="col-md-12">
              <hr>
            </div>
            <div class="col-md-12 ">
              <div class="d-flex justify-content-between mt-2 p-2 bg-FFB803">
                <h6 class="my-auto text-white">{{ $t('suppliers.contact_list') }}</h6>
                <button type="button" class="btn btn-primary btn-sm" @click="addContactRowToList">{{ $t('add_more') }}</button>
              </div>
            </div>
            <div class="col-md-12 bg-white pb-5">
              <table class="table table-row-bordered">
                <thead>
                <tr>
                  <th>{{ $t('suppliers.first_name') }}</th>
                  <th>{{ $t('suppliers.last_name') }}</th>
                  <th>{{ $t('suppliers.email') }}</th>
                  <th>{{ $t('suppliers.telephone') }}</th>
                  <th>{{ $t('suppliers.mobile') }}</th>
                  <th></th>
                </tr>

                </thead>
                <tbody>
                <tr v-for="(row, index) in contact_list" :key="index">
                  <td><input v-model="row.first_name" type="text" class="form-control"></td>
                  <td><input v-model="row.last_name" type="text" class="form-control"></td>
                  <td>
                    <input v-model="row.email" type="text" class="form-control" :class="validation && validation[`contact_list.${index}.email`] ? 'is-invalid' : ''">
                    <span v-if="validation && validation[`contact_list.${index}.email`]" class="fv-plugins-message-container invalid-feedback">
                          {{ validation[`contact_list.${index}.email`][0] }}
                        </span>
                  <td>
                    <!-- <input v-model="row.telephone" type="text" class="form-control"> -->
                    <vue-tel-input v-model="row.telephone"></vue-tel-input>
                  <span v-if="validation && validation[`contact_list.${index}.telephone`]" class="fv-plugins-message-container invalid-feedback">
                          {{ validation[`contact_list.${index}.telephone`][0] }}
                        </span>
                  </td>
                  <td>
                    <!-- <input v-model="row.mobile" type="text" class="form-control"> -->
                    <vue-tel-input v-model="row.mobile"></vue-tel-input>
                    <span v-if="validation && validation[`contact_list.${index}.mobile`]" class="fv-plugins-message-container invalid-feedback">
                          {{ validation[`contact_list.${index}.mobile`][0] }}
                        </span>
                  </td>
                  <td>
                    <v-icon style="color: #dc3545;" small v-if="contact_list.length > 1" @click="removeContactRowFromList(index)">mdi-delete</v-icon>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

        </b-tab>
        <b-tab :title="$t('suppliers.accounting_information')" @click="toggleTab('accounting_information')">
          <div class="card card-custom">
            <div class="card-body">
              <div class="form-group row">


                <!--                                    <div class="col-lg-6 mb-5">-->
                <!--                                        <label>{{$t('suppliers.open_balance')}}</label>-->
                <!--                                        <input type="number" v-model="data.open_balance" class="form-control" :class="validation && validation.open_balance ? 'is-invalid' : ''"/>-->
                <!--                                        <span v-if="validation && validation.open_balance" class="fv-plugins-message-container invalid-feedback">-->
                <!--                                            {{ validation.open_balance[0] }}-->
                <!--                                        </span>-->
                <!--                                    </div>-->
                <!--                                    <div class="col-lg-6 mb-5">-->
                <!--                                        <label>{{$t('suppliers.open_balance_date')}}</label>-->
                <!--                                        <input type="date" v-model="data.open_balance_date" class="form-control" :class="validation && validation.open_balance_date ? 'is-invalid' : ''"/>-->
                <!--                                        <span v-if="validation && validation.open_balance_date" class="fv-plugins-message-container invalid-feedback">-->
                <!--                                            {{ validation.open_balance_date[0] }}-->
                <!--                                        </span>-->
                <!--                                    </div>-->

                <!-- <div v-if="data.account_chart_id && accounts_list.length" class="col-lg-6 mb-5"> -->
                <div class="col-lg-6 mb-5">
                  <label>&ensp;</label>
                  <b-form-checkbox size="lg" v-model="use_tree_accounting" name="check-button" switch>{{ $t('suppliers.create_special_account_in_tree_accounting') }}</b-form-checkbox>
                </div>

                <div v-if="use_tree_accounting" class="col-lg-6 mb-5">
                  <label>{{ $t('suppliers.account') }}</label>
                  <!-- <treeselect
                          :options="accounts_list"
                          :load-options="loadOptions"
                          :multiple="false"
                          :value="data.parent_account_id"
                          @input="updateValueAccount"
                          :searchable="true"
                          :class="validation && validation.account_id ? 'is-invalid' : ''"
                          :show-count="true"
                          :no-children-text="$t('No_sub_options')"
                          :no-options-text="$t('No_options_available')"
                          :no-results-text="$t('No_results_found')"
                          :placeholder="$t('Select')">
                  </treeselect> -->
                  <div class="input-group mb-3">
                    <multiselect
                        v-model="parent_account"
                        @input="updateValue($event)"
                        :placeholder="$t('suppliers.account')"
                        label="label"
                        track-by="id"
                        :options="accounts_list"
                        :multiple="false"
                        :taggable="false"
                        :show-labels="false"
                        :show-no-options="false"
                        :show-no-results="false"
                        @search-change="getAccounts($event)">
                    </multiselect>
                    <div class="input-group-prepend">
                      <a class="btn btn-primary" href="/accounting/chart-accounts/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                    </div>
                  </div>
                  <span v-if="validation && validation.account_chart_id" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.account_chart_id[0] }}
                                        </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('suppliers.currencies_allowed') }}</label>
                  <div class="input-group mb-3">
                    <multiselect v-model="currencies_allowed_outer"
                                 :placeholder="$t('suppliers.currency')"
                                 label="name"
                                 track-by="id"
                                 :options="currencies"
                                 :multiple="true"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false">
                    </multiselect>
                    <div class="input-group-prepend">
                      <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                    </div>
                  </div>
                  <span v-if="validation && validation.currencies_allowed" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.currencies_allowed[0] }}
                                    </span>
                </div>
                <div class="col-lg-12 mb-5">
                  <label>{{ $t('suppliers.notes') }}</label>
                  <textarea v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>
                  <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.notes[0] }}
                                        </span>
                </div>

              </div>
              <div class="form-group row">
                <div class="col-md-12">
                  <hr>
                </div>
                <div class="col-md-12">
                  <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                    <h6 class="my-auto text-white">{{ $t('suppliers.balances') }}</h6>
                    <!--                                            <button type="button" class="btn btn-primary btn-sm" @click="addItemRowToList">{{$t('add_more')}}</button>-->
                  </div>
                  <div class="table-responsive">
                    <!--                                            <table class="table table-row-bordered" @keyup.enter="addItemRowToList" @keyup.46="removeItemRowFromList">-->
                    <table class="table table-row-bordered">
                      <thead>
                      <tr>
                        <th>{{ $t('suppliers.balance') }}</th>
                        <!--                                                    <th>{{$t('suppliers.credit')}}</th>-->
                        <th>{{ $t('suppliers.debit') }}</th>
                        <th>{{ $t('suppliers.currency') }}</th>
                        <th>{{ $t('suppliers.last_validated_date') }}</th>
                        <th></th>
                      </tr>

                      </thead>
                      <tbody>
                      <template v-if="items_list.length > 0">
                        <tr v-for="(row, index) in items_list" :key="index">

                          <!--                                                    <td><input v-model="row.balance" :disabled="true" type="number" min="0.01" step="0.01" class="form-control"></td>-->
                          <td><input v-model="row.credit" :disabled="true" type="number" min="0.01" step="0.01" class="form-control"></td>
                          <td><input v-model="row.debit" :disabled="true" type="number" min="0.01" step="0.01" class="form-control"></td>
                          <td>
                            <select name="" :disabled="true" v-model="row.currency_id" class="custom-select">
                              <option v-for="cc in currencies" :value="cc.id" :key="cc.id">{{ cc.name }}</option>
                            </select>
                          </td>
                          <td>
                            {{ row.last_validated_date }}
                          </td>
                          <td>
                            <v-icon style="color: #dc3545;" color="danger" small v-if="items_list.length > 1 && !row.id" @click="removeItemRowFromList(index)">mdi-delete</v-icon>
                          </td>
                        </tr>
                      </template>
                      <tr v-else>
                        <td colspan="4">{{ $t('no_balances') }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab :title="$t('suppliers.credit_and_debit')" v-if="$can('suppliers.min_max_credit_debit')" :disabled="!idEditing" @click="toggleTab('credit_and_debit')">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('suppliers.minimum_credit') }}</label>
                <input type="number" v-model="data.minimum_credit" class="form-control" :class="validation && validation.minimum_credit ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.minimum_credit" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.minimum_credit[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('suppliers.maximum_credit') }}</label>
                <input type="number" v-model="data.maximum_credit" class="form-control" :class="validation && validation.maximum_credit ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.maximum_credit" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.maximum_credit[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('suppliers.minimum_debit') }}</label>
                <input type="number" v-model="data.minimum_debit" class="form-control" :class="validation && validation.minimum_debit ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.minimum_debit" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.minimum_debit[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('suppliers.maximum_debit') }}</label>
                <input type="number" v-model="data.maximum_debit" class="form-control" :class="validation && validation.maximum_debit ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.maximum_debit" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.maximum_debit[0] }}
                            </span>
              </div>

            </div>
          </div>
        </b-tab>

        <b-tab :title="$t('suppliers.address_info')" :disabled="!idEditing" @click="toggleTab('address_info')">
          <div class="card card-custom">
            <div class="card-body">
              <address-info :supplier-id="idEditing"></address-info>
            </div>
          </div>
        </b-tab>
        <b-tab :title="$t('suppliers.activity_log')"  :disabled="!idEditing" @click="toggleTab('activity_log')">
          <div class="card card-custom">
            <div class="card-body row">
              <activity-log :supplier-id="idEditing"></activity-log>
            </div>
          </div>
        </b-tab>
        <!-- <b-tab :title="$t('suppliers.contact_info')" @click="toggleTab('contact_info')">

        </b-tab> -->
      </b-tabs>


      <div class="pl-0 pr-0" v-if="tab_name != 'activity_log'">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="reset" class="btn btn-primary mr-2" @click="save()" v-if="tab_name != 'address_info'">{{ $t('save') }}</button>
            <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
          </div>
        </div>
      </div>
      <!--      </div>-->
    </div>
    <!--end::customer-->
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import AddressInfo from "./AddressInfo";
import ActivityLog from "./ActivityLog";
import {mapGetters, mapState} from "vuex";


export default {
  name: "form-suppliers",
  components: {'address-info': AddressInfo, 'activity-log': ActivityLog},
  data() {
    return {
      mainRoute: 'purchases/suppliers',
      mainRouteDependency: 'base/dependency',

      data: {
        business_name: null,
        first_name: null,
        last_name: null,
        phone: null,
        mobile: null,
        country_code: null,
        city_id: null,
        state: null,
        postal_code: null,
        address_1: null,
        address_2: null,
        is_active: true,
        supplier_code: null,
        email: null,
        default_currency: null,
        open_balance: null,
        balance: null,
        open_balance_date: null,
        notes: null,
        parent_account_id: null,
        account_chart_id: null,
        buisnessno: null,
        taxno: null,
        currencies_allowed: [],
        branch_id: null,
        minimum_credit: 0,
        maximum_credit: 0,
        minimum_debit: 0,
        maximum_debit: 0,
      },
      idEditing: this.$route.params.id ? this.$route.params.id : null,
      isEditing: false,
      countries: [],
      cities: [],
      currencies: [],
      accounts_list: [],
      use_tree_accounting: false,
      validation: null,
      country: "",
      city: "",
      tab_name: 'basic_information',
      parent_account: [],
      contact_list_form: {first_name: null, last_name: null, email: null, telephone: "", mobile: ""},
      contact_list: [],
      items_list: [],
      items_list_form: {id: null, balance: null, currency_id: null, last_validated_date: null, last_amount: null, credit: null, debit: null},
      currencies_allowed_outer: [],
      branch: null,
      branches: [],
    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },
  watch: {

    country: function (val) {
      if (val) {
        this.data.country_code = val.code2;
        this.getCities(val.code2);
      } else {
        this.data.country_code = null;
      }
    },
    city: function (val) {
      if (val) {
        this.data.city_id = val.id;
      } else {
        this.data.city_id = null;
      }
    },
    parent_account: function (val) {
      if (val) {
        this.data.parent_account_id = val.id;
        this.data.account_chart_id = val.id;
      } else {
        this.data.parent_account_id = null;
        this.data.account_chart_id = null;
      }
    },
    // 'data.business_name': function (val) {
    //     if (val){
    //         this.data.business_name = val.replace(/[\d]/g, "");
    //     }
    // },
    // 'data.first_name': function (val) {
    //     if (val){
    //         this.data.first_name = val.replace(/[\d]/g, "");
    //     }
    // },
    // 'data.last_name': function (val) {
    //     if (val){
    //         this.data.last_name = val.replace(/[\d]/g, "");
    //     }
    // },
    // 'data.state': function (val) {
    //     if (val){
    //         this.data.state = val.replace(/[\d]/g, "");
    //     }
    // },
    currencies_allowed_outer: function (val) {
      this.data.currencies_allowed = [];
      if (val) {
        this.data.currencies_allowed = val.map((row) => row.id);
      }
    },
    branch: function (val) {
      if (val) {
        this.data.branch_id = val.id;
      } else {
        this.data.branch_id = null;
      }
    },
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      let that = this;
      this.data.balance = this.data.open_balance
      // this.data.is_active = this.data.is_active?1:0;
      this.contact_list.map((row)=>{
        if (row.telephone.length < 5){
          row.telephone = '';
        }
        if (row.mobile.length < 5){
          row.mobile = '';
        }
        return row;
      });

      ApiService.post(`${this.mainRoute}`, {
        tab_name: that.tab_name,
        contact_list: that.contact_list,
        items_list: that.items_list,
        ...that.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.$router.push({name: 'suppliers.index'});
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {
      let that = this;
      this.data.balance = this.data.open_balance
      // this.data.is_active = this.data.is_active?1:0;
      this.contact_list.map((row)=>{
        if (row.telephone.length < 5){
          row.telephone = '';
        }
        if (row.mobile.length < 5){
          row.mobile = '';
        }
        return row;
      });
      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        tab_name: that.tab_name,
        contact_list: that.contact_list,
        items_list: that.items_list,
        ...that.data,
      })
          .then((response) => {
            this.validation = null;
            this.$router.push({name: 'suppliers.index'});
            this.$successAlert(response.data.message);
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {
      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.business_name = response.data.data.business_name;
        this.data.first_name = response.data.data.first_name;
        this.data.last_name = response.data.data.last_name;
        this.data.phone = response.data.data.phone ? response.data.data.phone : null;
        this.data.mobile = response.data.data.mobile ? response.data.data.mobile : null;
        this.data.country_code = response.data.data.country_code;
        this.data.city_id = response.data.data.city_id;
        this.country = response.data.data.country;
        this.city = response.data.data.city;
        this.data.state = response.data.data.state;
        this.data.postal_code = response.data.data.postal_code;
        this.data.address_1 = response.data.data.address_1;
        this.data.address_2 = response.data.data.address_2;
        this.data.is_active = response.data.data.is_active;

        this.data.supplier_code = response.data.data.supplier_code;
        this.data.email = response.data.data.email;
        this.data.default_currency = response.data.data.default_currency;
        this.data.open_balance = response.data.data.open_balance;
        this.data.open_balance_date = response.data.data.open_balance_date;
        this.data.notes = response.data.data.notes;
        this.data.buisnessno = response.data.data.buisnessno;
        this.data.taxno = response.data.data.taxno;
        this.data.currencies_allowed = response.data.data.currencies_allowed;
        this.contact_list = response.data.data.contact_list ? response.data.data.contact_list : [];
        this.data.branch_id = response.data.data.branch_id;
        this.branch = response.data.data.branch;
        this.data.minimum_credit = response.data.data.minimum_credit;
        this.data.maximum_credit = response.data.data.maximum_credit;
        this.data.minimum_debit = response.data.data.minimum_debit;
        this.data.maximum_debit = response.data.data.maximum_debit;

        if (response.data.data.account_chart_id) {
          this.parent_account = response.data.data.parent_account;
          this.use_tree_accounting = true;
        }

        if (response.data.data.contact_list && response.data.data.contact_list.length <= 0) {
          this.addContactRowToList();
        }

        // if (!response.data.data.items_list || (response.data.data.items_list && response.data.data.items_list.length <= 0)) {
        //     this.addItemRowToList();
        // }else {
        this.items_list = response.data.data.items_list ? response.data.data.items_list : [];
        // }
        this.currencies_allowed_outer = this.currencies.filter((row) => response.data.data.currencies_allowed.includes(row.id));
      });
    },

    getCountries(filter) {
      if (filter && filter.length >= 3)
        ApiService.get("base/dependency/countries", {params: {filter: filter}}).then((response) => {
          this.countries = response.data.data.filter(row => row.name != null);
        });
    },

    getDefaultCountries() {
      ApiService.get(`${this.mainRouteDependency}/default-country`).then((response) => {
        this.country = response.data.data;
      });
    },

    async getCurrencies() {
      await ApiService.get("base/dependency/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getCities(id) {
      if (id)
        ApiService.get(`base/dependency/cities/${id}`).then((response) => {
          this.cities = response.data.data;
        });
    },


    loadOptions({action, searchQuery}) {
      if (searchQuery && searchQuery.length >= 3 && action)
        this.getAccounts(searchQuery);

    },
    updateValue(value) {
      this.data.parent_account_id = value;
    },
    // loadOptions() {
    // },
    updateValueAccount(value) {
      this.data.parent_account_id = value;
    },
    toggleTab(tab_name) {
      this.tab_name = tab_name
    },

    addContactRowToList() {
      this.contact_list.unshift(this.contact_list_form);
      this.contact_list_form = {first_name: null, last_name: null, email: null, telephone: "", mobile: ""};
    },
    removeContactRowFromList(index) {
      this.contact_list.splice(index, 1);
    },
    getCode() {
      ApiService.get(this.mainRouteDependency + "/code-setting/2").then((response) => {
        this.data.supplier_code = response.data.data.code;
      });
    },

    // getAccounts() {
    //     ApiService.get(this.mainRouteDependency + `/account_parents`,{params:{checkDisabled: 1,key:'suppliers'}}).then((response) => {
    //         this.accounts_list = response.data.data;
    //     });
    // },
    getAccounts(filter) {
      if (filter && filter.length >= 3)
        return ApiService.get(this.mainRouteDependency + `/account_parents_leaf_nodes`, {params: {name: filter}}).then((response) => {
          this.accounts_list = response.data.data;
        });
    },
    getDefaultAccount() {
      ApiService.get(`${this.mainRouteDependency}/get_default_account/suppliers`).then((response) => {
        this.parent_account = response.data.data;
      });
    },


    addItemRowToList() {
      this.items_list.unshift(this.items_list_form);
      this.items_list_form = {id: null, balance: null, currency_id: null, last_validated_date: null, last_amount: null, credit: null, debit: null};
    },
    removeItemRowFromList(index) {
      if (this.items_list.length > 1) {
        this.items_list.splice(index, 1);
      }
    },


    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
        if (!this.idEditing) {
          this.branch = this.branches.find(option => option.is_default == 1);
        }

      });
    },

    defaultDataForUser() {
      ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.country = response.data.data.country;
        this.data.country_code = response.data.data.country_id;
        this.city = response.data.data.city;
        this.data.city_id = response.data.data.city_id;
        this.data.default_currency = response.data.data.currency_id;
        this.currency = response.data.data.currency;
        this.data.branch_id = response.data.data.branch_id;
        this.branch = response.data.data.branch;
      });
    }

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.suppliers_management"), route: '/purchases/suppliers'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);
    this.getCountries();
    // this.getDefaultCountries();
    // this.getDefaultCurrency();
    let promise = this.getCurrencies();
    // this.getAccounts();

    this.getDefaultAccount();
    this.getBranches();

    if (this.idEditing) {
      Promise.all([promise]).then(() => {
        this.getData();
      });

    } else {
      if (this.contact_list.length <= 0) {
        this.addContactRowToList();
      }
      // this.addItemRowToList();
      this.getCode();
      this.data.open_balance_date = new Date().toISOString().slice(0, 10);
      this.defaultDataForUser();
    }

  },
};
</script>
